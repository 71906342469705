<template>
  <div>
    <b-container class="base-container-x member-park-header-box" style="min-height:500px;background-color:#fff">
      <div v-if="loading === true" class="col-12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </div>
      <div v-if="loading === false" id="banner" class="banner-inner member-park">
        <!-- <div class="slideshow_caption">
          <div class="slideshow_inner_caption">
            <div class="slideshow_align_caption">
              <h1 class="section-header-title text-uppercase">{{ $t('MEMBER PARK') }}</h1>
              <h3 class="article-content">{{ $t('Looking for new yoga accessories? Visit our partner stores worldwide.') }}</h3>
            </div>
          </div>
        </div> -->
      </div>
      <div v-if="loading === true" class="col-12 mt-4">
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
        <br/>
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
      </div>
      <div v-if="loading === false" class="col-12 mb-5 pt-4 text-center">
        <b-img blank-color="#fff" class="w-25" :src="require('../../assets/images/icon/coming_soon.png')"></b-img>
        <h5>{{ $t('We are currently developing this service.') }}</h5>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'MemberPark',
  title: ' | Member Park',
  data () {
    return {
      loading: true
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>

<style>

</style>
